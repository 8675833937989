import {Routes} from '@angular/router';
import {authGuard} from "./core/api-services/guard/auth.guard";
import {NotFoundComponent} from "./shared/components/not-found/not-found.component";

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./routes/auth.route').then((m) => m.AuthRoutes),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./routes/dashboard.route').then((m) => m.DashboardRoute),
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent}
];
