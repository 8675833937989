import {
  Component,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {TextComponent} from "./shared/components/text/text.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {CalendarComponent} from "./shared/components/calendar/calendar.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    CalendarComponent,
    TextComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public value!: Date;
  public format = "MM/dd/yyyy HH:mm";

  constructor(
    public matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'hint', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/hint-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-down-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'flag', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flag.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'file', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'checked', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/checked-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'add-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'edit-pencil-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-pencil-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'bulk-edit-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bulk-edit-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'notification', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'more', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/more-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'expander', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/expander-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'contact', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'archive', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/archive-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'settings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'filter', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sort', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sort-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'drag', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drag-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'image', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/image-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'video', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'hamburger-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-more-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'type-col', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/type-col.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'type-list', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/type-list.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'search-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'plus', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'edit-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'show-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/show-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'add-repeater-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_repeater-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'delete-repeater-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete_repeater-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'filter-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter_menu-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'filtered-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filtered_menu-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'manage_entity_list', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/manage_entity_list-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'drag-menu-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drag-menu-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'color-status-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/color_status-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'freeze-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/freeze-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'save-screen-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save_screen-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'save-screen-white-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save-screen-white-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'white-arrow-down-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/white-arrow-down-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'upload-file-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload-file-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'action-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/action-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'upload-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'upload-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'generate-document-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/generate-document-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-top-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-top-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'save-screen-grey-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save_screen_grey-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'grey-close-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/grey_close-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pipeline-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pipeline-icon.svg'),
    );

    //menu icons
    this.matIconRegistry.addSvgIcon(
      'campaigns', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/campaign-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'companies', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'contact_persons', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact_person-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'shipments', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shipment-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'requests', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'transporting_units', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck_container-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'employees', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/employee-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'locations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/locations-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'providers', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/providers-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'documents', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/documents-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company_translations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company_transaction_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company_special_requirements', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company_spec-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company_unloading_places', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company_unload-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'contact_person_phone_numbers', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact_person_phone-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'contact_person_emails', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact_person_mail-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'warehouses', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/warehouses-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'agent_quotations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agent_quotations-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'agent_quotations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agent_quotations-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'logistics_lines', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logistics_line-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'unloadings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/unloadings-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'loadings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/loadings-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'hs_codes', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/hs_code-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'total_prices', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/total_price-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'currencies', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/currency-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'capacities', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/capacity-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'statuses', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/status-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'agents', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agent-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'agent_destinations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agent_destination-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'check_points', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check_points-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'customs_points', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom_points-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'agent_costs', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agents_costs-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'provider_costs', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/providers_and_costs-icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'location_types', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/providers_and_costs-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cargo_containers', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/providers_and_costs-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'save-screen-plus', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save-screen-plus.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'save-screen-plus-green', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save-screen-plus-green.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'edit-entity-pen', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-entity-pen.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'shippers', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shippers.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company_connections', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company_connections.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company_contact_persons', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company_contact_persons.svg'),
    );
  }
}
