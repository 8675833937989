import {HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from "@angular/core";
import {AuthService} from "../auth.service";
import {catchError, Observable, throwError} from "rxjs";
import {Router} from "@angular/router";

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const authToken = authService.getAuthToken()
  const router = inject(Router);

  const cloned = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken ?? ''}`
    }
  });

  function handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401 || error.status === 403) {
      authService.logout();
      void router.navigate([`/login`]);
      return throwError(() => error);
    }
    return throwError(() => error);
  }

  return next(cloned).pipe(catchError(x => handleAuthError(x)));
};
