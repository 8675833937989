import {AfterViewInit, Component, ElementRef, HostBinding, inject} from '@angular/core';
import {ControlInterface} from "../../../core/interfaces/control.interface";
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MatFormField} from "@angular/material/form-field";

@Component({
  standalone: true,
  imports: [],
  templateUrl: './base.component.html',
})
export class BaseComponent implements ControlInterface {
  @HostBinding('style.width') width: string = '';
  public group!: FormGroup;
  public viewMode: boolean = false;
  public props: any;
  public label: any;
  public placeholder: any;
  public hint: any;
  public formId: string | undefined;
  public colors: {
    code: string,
    id: number,
    name: string
  }[] = [];
  public required: boolean = false;
  public entity: string | null = null;
  public baseEntity: string | null = null;
  public defaultColorId: number | null = null;
  public validators: { name: string, value: string[], error_message: string }[] = [];
  public breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  public setMode(isViewMode: boolean): void {
    if (isViewMode && this.group) {
      this.group.reset();
    }
    this.viewMode = isViewMode;
  }

  public setWidth(width: number): void {
    if (!this.breakpointObserver.isMatched('(max-width: 431px)')) {
      this.width = +width === 50 ? `calc(${width}% - 5px)` : `${width}%`;
    } else {
      this.width = '100%';
    }
  }

  public setProps(props: any): void {
    this.props = props;
  }

  public setLabel(label: any): void {
    this.label = label;
  }

  public setHint(hint: any): void {
    this.hint = hint;
  }

  public setPlaceholder(placeholder: string): void {
    this.placeholder = placeholder;
  }

  public setColors(colors: {
    code: string,
    id: number,
    name: string
  }[]): void {
    this.colors = colors;
  }

  public setEntity(entity: string): void {
    this.entity = entity;
  }

  public setGroup(control: AbstractControl): void {
    this.group = control as FormGroup;
  }

  public setBaseEntity(entity: string): void {
    this.baseEntity = entity;
  }

  public setDefaultColorId(id: number | null): void {
    this.defaultColorId = id;
  }

  public onColorSet(name: string, color: { code: string, id: number, name: string }, controlRef: MatFormField): void {
    this.group.get(name)?.get('color_id')?.setValue(color.id === 0 ? null : color.id);
    controlRef._elementRef.nativeElement.querySelector('.mat-mdc-text-field-wrapper').style.backgroundColor = color.code;
  }

  public setViewModeColor(colorId: number): string | undefined {
    return this.colors.find((color): boolean => color.id === colorId)?.code;
  }

  public setEditModeColor(colorId: number, controlRef?: MatFormField): void {
    if (controlRef) {
      controlRef._elementRef.nativeElement.querySelector('.mat-mdc-text-field-wrapper').style.backgroundColor =
        this.colors.find((color): boolean => color.id === colorId)?.code;
    }
  }

  public setFormId(id: string): void {
    this.formId = id;
  }
}
