import {Injectable} from '@angular/core';
import {BaseApiService} from "./base-api.service";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {UserCredentials} from "../models/auth/user-credentials.model";
import {CURRENT_USER, USER_TOKEN} from "../../util/constants";
import {Response} from "../models/response.model";
import {UserToken} from "../models/auth/user-token.model";
import {User} from "../models/auth/user.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  private userToken = new BehaviorSubject<string | null>(null);
  private currentUser = new BehaviorSubject<User | null>(null);

  isLoggedIn$ = this.userToken.asObservable().pipe(map(token => !!token && !!localStorage.getItem(USER_TOKEN)));
  currentUser$ = this.currentUser.asObservable();

  constructor() {
    super();
    const storedUser = localStorage.getItem(CURRENT_USER);

    this.userToken.next(localStorage.getItem(USER_TOKEN));
    this.currentUser.next(storedUser ? JSON.parse(storedUser) as User : null);
  }

  login(credentials: UserCredentials): Observable<Response<UserToken>> {
    return this.http.post<Response<UserToken>>(`${environment.baseApiExternal}sign-in`, credentials)
      .pipe(
        tap((response: Response<UserToken>) => {
          localStorage.setItem(USER_TOKEN, JSON.stringify(response.data.token));
          this.userToken.next(response.data.token);
          this.fetchCurrentUser();
        })
      );
  }

  private fetchCurrentUser(): void {
    this.http
      .get<Response<{ user: User }>>(`${environment.baseApiExternal}user/current`)
      .pipe(
        tap(response => {
          this.currentUser.next(response.data.user);
          localStorage.setItem(CURRENT_USER, JSON.stringify(response.data.user));
        })
      )
      .subscribe();
  }

  logout(): void {
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(CURRENT_USER);
    this.userToken.next(null);
    this.currentUser.next(null);
  }

  getAuthToken(): string | null {
    return this.userToken.value;
  }
}
